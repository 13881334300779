<template>
  <div>
    <!-- Category Info: Input Fields -->
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent="validationForm">
        <b-row v-if="category">
          <!-- Field: Name -->
          <b-col cols="12" md="6">
            <validation-provider
              #default="{ errors }"
              name="Nombre"
              rules="required"
            >
              <b-form-group label="Nombre de Categoria" label-for="full-name">
                <b-form-input id="full-name" v-model="category.name" />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <!-- Field: Tax code -->
          <b-col cols="12" md="6">
            <validation-provider
              #default="{ errors }"
              name="Código de categoria SAT"
              rules="required|numeric|digits:8"
            >
              <b-form-group label="Código de categoria SAT" label-for="TaxCode">
                <b-form-input
                  id="tax-code"
                  v-model="category.tax_code"
                  type="number"
                  maxlength="8"
                  min="01010101" 
                  max="99999999"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
        </b-row>

        <!-- Action Buttons -->
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          type="submit"
        >
          Guardar
        </b-button>
        <b-button
          variant="outline-secondary"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="$router.go(-1)"
        >
          Cancelar
        </b-button>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import vSelect from "vue-select";
import { mapActions } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import useCategoriesList from './useCategoriesList'

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    category: {
      type: Object,
      required: false,
      default: () => ({
        name: "",
      }),
    },
  },
  setup(props) {
    const {
      resolveStatusVariant,
    } = useCategoriesList()

    const statusOptions = [
      { label: "Active", value: "active" },
      { label: "Inactive", value: "inactive" },
    ];

    return {
      resolveStatusVariant,
      statusOptions,
    };
  },
  data() {
    return {
      required,
      email,
    };
  },
  methods: {
    ...mapActions("categories", ["editCategory"]),
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.editCategory({
            id: this.$route.params.id,
            category: {
              name: this.category.name,
              tax_code: this.category.tax_code,
            },
          }).then(() => {
            this.$router.go(-1);
          });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
