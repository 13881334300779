import { ref, watch, computed } from '@vue/composition-api'

export default function useCategoriesList() {

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'name', label: 'Nombre', sortable: true },
    { key: 'status', label: 'Estatus', sortable: true },
    { key: 'actions', label: 'Herramientas' },
  ]
  const perPage = ref(10)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  
  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const resolveStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === true) return 'success'
    if (status === false) return 'secondary'
    return 'primary'
  }

  return {
    tableColumns,
    perPage,
    currentPage,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    resolveStatusVariant,
    refetchData,
  }
}
