<template>
  <component :is="category === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="category === undefined">
      <h4 class="alert-heading">Error al buscar categoria</h4>
      <div class="alert-body">
        Categoria no encontrado
        <b-link class="alert-link" :to="{ name: 'categories-list' }">
          Revisa la lista de categorias
        </b-link>
      </div>
    </b-alert>

    <b-card-title> Editar categoria </b-card-title>

    <category-edit-tab
      v-if="category !== undefined"
      :category="category"
    />
  </component>
</template>

<script>
import { ref } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import CategoryEditTab from './CategoryEditTab.vue'



export default {
  components: {
    CategoryEditTab ,
  },
  setup() {
    const category = ref(null);

    store
      .dispatch("categories/fetchCategory", router.currentRoute.params.id)
      .then((response) => {
        console.log(response);
        category.value = response;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          category.value = undefined;
        }
      });

    return {
      category,
    };
  },
};
</script>

<style></style>
